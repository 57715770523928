@import '../../../../styles/variables.scss';

.container{
	margin-top: $margin-top-section;
	padding-left: 120px;
	padding-right: 120px;
	padding-bottom: 40px;
}
.title{
	color: $color-magic-green;
	font-size: 32px;
	text-transform: uppercase;
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;
} 
.text p{
	font-family: 'Sofia Pro Medium Az';
	font-size: 18px;
	color: #FFFFFF;
	line-height: 26px;
}
.text .green{
	color: $color-magic-green;
}
.text .purple{
	color: $color-mid-purple;
}
.image{
	text-align: center;
	margin: 0 auto;
	width: 100%;
}
.section{
	margin-top: 140px;	
}


.titleMagic{
	display: flex;
	align-items: center;	
}
.coin{
	width: 40px;
	margin-right: 10px;
}

.textAudiovisual{
	padding-top: 100px;
}
.imageAudiovisual{
	width: 460px;
	margin: 0 auto;
	display: flex;
}

@media(max-width: 768px){

	.container{
		padding-left: 10px;
		padding-right: 10px;		
	}

}