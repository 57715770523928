@import '../../../../styles/variables.scss';

.container{
	margin-top: $margin-top-section;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 40px;
}
.title{
	text-transform: uppercase;
	color: $color-magic-green;
	font-size: 32px;
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;
	text-align: center;
}
.subtitle{
	font-size: 18px;	
	font-family: 'Sofia Pro Medium Az';
	letter-spacing: 0.2px;
	text-align: center;
	color: #ffffff;
}
.purple{
	color: $color-mid-purple;
}
.green{
	color: $color-magic-green;
}
.box{	
	margin: 0 auto;
	display: flex;	
	border-radius: 26px;
	width: 66%;
	border: 5px solid $color-magic-green;
}
.action{
	font-family: 'Sofia Pro Medium Az';
}
.frameBid{
	padding-top: 10px;
} 
.frameEnd{	
	border-left: 3px solid #933bff;
	height: 93px;
	padding-top: 9px;
}
.bid{
	color: #ffffff;
	font-size: 16px;
	font-weight: bold;	
}
.end{
	color: #ffffff;
	font-size: 16px;
	font-weight: bold;	
	text-align: center;	
}
.total{
	color: #00ffa1;
	font-size: 20px;
	font-weight: bold;
	margin-top: -12px;
}
.clock{
	color: #00ffa1;
	font-size: 22px;	
	margin-top: -12px;
	text-align: center;
}
.frameValue{
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 40px;
	padding-bottom: 5px;
	background-color: $color-mid-purple;
	margin-top: 30px;
	border-radius: 26px;
}
.entry{
	font-family: 'Sofia Pro Medium Az';
	color: #ffffff;
	border-bottom: 1px solid #ffffff;
	font-size: 18px;	
	letter-spacing: 0.5px;	
}
.value2{
	text-align: right;
}
.frameValueExternal{
	padding-left: 0;
	padding-right: 16px;	
}
.inputValue{
	margin-top: 20px;
	border-radius: 26px;
	background-color: #111111;		
	color: #ffffff;
	font-size: 16px;	
	font-family: 'Sofia Pro Medium Az';
	border: 3px solid $color-magic-green;
}
.input{
	background: none;
	border: none;
	color: #fff;
	height: 34px;	
	width: 74%;
	padding-left: 16px;
	font-size: 16px;	
	font-family: 'Sofia Pro Medium Az';
	outline: none;
}
.btnBid{
	text-align: center;
	margin-top: 16px;
}
.btnBid button{
	width: 72%;
	background-color: $color-magic-blue;
	border-radius: 15px;
	height: 42px;
	font-size: 18px;	
	font-family: 'Sofia Pro Medium Az';

	&:hover{
		background-color: #0e0759;
	}
}
.info{
	text-align: center;
	font-family: 'Sofia Pro Medium Az';
	font-size: 14px;
	color: #ffffff;
}
.fooText{
	text-align: center;
	font-family: 'Sofia Pro Medium Az';
	color: #ffffff;
	text-align: left;
}
.fooTitle{
	font-size: 20px;
}
.fooSubtitle{
	font-size: 18px;
	margin-top: -13px;
	font-style: italic;
}
.borderRight{
	border-right: 1px solid $color-magic-green;
}
.section{
	margin-top: 80px;
}
.titleSection{
	color: $color-magic-green;
	font-size: 32px;
	text-transform: uppercase;
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;
}
.text p{
	font-family: 'Sofia Pro Medium Az';
	font-size: 18px;
	color: #FFFFFF;
	line-height: 26px;
}
.text .green{
	color: $color-magic-green;
}
.text .purple{
	color: $color-mid-purple;
}
.image{
	text-align: center;
	margin: 0 auto;
	width: 100%;
}
.video{
	margin: 0 auto;	  
	display: flex;
	margin-top: 40px;
	margin-bottom: 40px;
	width: 360px;
	border: 5px solid $color-magic-green;
	border-radius: 26px;
}

@media(max-width: 768px){

	.container{		
		padding-left: 10px;
		padding-right: 10px;
	}
	.box{			
		width: 90%;		
	}
	.bid{		
		font-size: 18px;		
	}
	.total{
		font-size: 20px;
	}
	.end{		
		font-size: 18px;		
	}
	.clock{
		font-size: 20px;
	}
	.frameBid{
		margin-left: 10px;
	}
	.frameEnd{			
		height: 80px;
		padding-top: 10px;
	}
	.frameValueExternal{
		padding-left: 5px;
		padding-right: 5px;
	}
	.borderRight{
		border: none;
	}
	.video{
		width: 90%;
	}

}