.content{	
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.logo{
	margin: 0 auto;
	display: block;
	width: 100%;
}
.title{
	text-align: center;
	font-family: 'Samuelstype - MagicaRuby-VBold';
	color: #a363ff;
	font-size: 44px;
	margin-top: 10px;	
}
.title span{
	text-transform: capitalize;
}
.title sup{
	font-size: 20px;
}
.btnJoin{
	text-align: center;	
}
.btnJoin button{
	background-color: #8229ea;
	color: #FFFFFF;
	font-family: 'FatFrank-Free';	
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 18px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 29px;
	height: 50px;

	&:hover{
		background-color: #933bff;
	}

}
.discord{
	width: 25px;
	margin-right: 8px;
}


@media(max-width: 768px){

	.content{
		padding-left: 10px;
		padding-right: 0px;
	}
	.title{				
		font-size: 24px;		
	}
	.subtitle{
		font-size: 18px;
	}

}