@import '../../styles/variables.scss';

.footer{    
    padding-top: 90px;
    padding-bottom: 10px;
    background: #080038;
    background: radial-gradient(circle farthest-corner at center center, #080038 46%, #A363FF 52%, #080038 63%);    
}
.logo{
    margin: 0 auto;
    display: block;
    width: 460px;
}
.content{
    text-align: center;
}
.title{
    text-align: center;
    font-family: 'Samuelstype - MagicaRuby-VBold';
    color: #ffffff;
    font-size: 40px;
    margin-top: 10px;   
    margin-bottom: 80px;
}
.title span{
    text-transform: capitalize;
}
.title sup{
    font-size: 20px;
}
.menu {
    margin:auto;    
    padding-top:5px;   
    height:25px;
    padding-bottom:5px;  
    display: flex;
    justify-content: center;      
}
.menu ul{
    list-style:none; /* Eliminamos los bullets */
    margin:0px; /* Quitamos los margenes */
    padding:0px; /* Quitamos el padding */
}
.menu ul li {
    float:left; /* Hacemos que el menu se muestre horizontal */
    padding-left:10px;
    padding-right:10px;
    text-transform: uppercase;    
}
.menu ul li a{
    text-decoration:none;
    color:#ffffff;    
    font-family: 'Sofia Pro Black Az';
    letter-spacing: -1px;
    font-size: 14px;
}
.sign{
    text-align: right;
    color: #ffffff;
    padding-right: 20px;
}
.sign .top{
    font-size: 14px;
    margin-right: 40px;
}
.sign .magicLabs{
    width: 100px;
}


@media(max-width: 768px){

    .logo{
        margin: 0 auto;
        display: block;
        width: 50%;
    }
    .title{        
        font-size: 20px;   
        margin-bottom: 18px;     
    }
    .sign{
        padding-top: 20px;
    }
    .menu ul li {
        float:none; /* Hacemos que el menu se muestre horizontal */
        margin-bottom: 2px;
    }

}


