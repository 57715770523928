@import '../../../../styles/variables.scss';

.content{
	padding-left: 120px;
	padding-right: 120px;
	background-image: url("../../../../assets/stone-table.png");
	background-repeat: no-repeat, repeat;
	background-position: center;
	background-size: cover;
}
.title{
	text-transform: uppercase;
	color: $color-magic-green;
	font-size: 42px;
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;
}
.subtitle{
	color: $color-mid-purple;
	font-size: 22px;
	font-family: 'Samuelstype - MagicaRuby-VBold';
	letter-spacing: 0.2px;
	margin-bottom: -28px;
}
.paragraph{	
	font-family: 'Sofia Pro Medium Az';
	font-size: 18px;
	color: #FFFFFF;
}
.paragraph .green{
	color: $color-magic-green;
}
.paragraph .purple{
	color: $color-mid-purple;
}
.chair{
	width: 250px;
	margin: 0 auto;
	display: flex;
	border-radius: 50%;
	margin-top: 48px;
	margin-bottom: 20px;
}
.mint{
	background-color: #8229ea;
	border-radius: 43px;
	padding-left: 40px;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-right: 40px;
	color: #ffffff;
}
.mint .izq p{
	font-family: 'Sofia Pro Medium Az';
	font-size: 14px;	
}
.mint .der .title{
	font-family: 'FatFrank-Free';
	font-size: 24px;
	color: #FFFFFF;
	text-align: center;	
	letter-spacing: 1px;
}
.mint .der .number{
	text-align: center;
	font-family: 'Sofia Pro Medium Az';
	font-size: 20px;
	margin-top: -20px;
}
.mint .der .entry{
	font-family: 'Sofia Pro Medium Az';
	border-bottom: 1px solid;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.5px;
}
.mint .der .entry .value{
	text-align: right;
}
.mint .der .btn{
	margin-top: 20px;
	text-align: center;
}
.mint .der .btn button{
	background-color: $color-magic-blue;
	color: #ffffff;
	border-radius: 26px;	
	font-size: 20px;
	font-weight: bold;
	width: 85%;
	height: 36px;
}
.mint .der .info{
	text-align: center;
	font-size: 12px;
	font-family: 'Sofia Pro Medium Az';
}
.video{
	margin: 0 auto;	  
	display: flex;
	margin-top: 40px;
	margin-bottom: 40px;
	width: 300px;
	border: 5px solid $color-magic-green;
	border-radius: 26px;
}

.error{
	color: black;
	text-align: center;	
}

.modalText{
	text-align: center;	
	font-family: 'Sofia Pro Medium Az';
	color: #050038;	
}
.modalText .titleOver{
	font-size: 24px;
	margin-top: 16px;
	margin-bottom: -13px;
}
.modalText .comment{
	font-size: 18px;
	color: #0a8e25;
}

@media(max-width: 768px){

	.content{
		padding-left: 10px;
		padding-right: 10px;
	}
	.chair{
		width: 80%px;		
		margin-top: 0px;
		margin-bottom: 60px;
	}
	.mint{		
		padding-left: 12px;		
		padding-right: 12px;		
	}
	.video{
		width: 90%;
	}

}