@import "../../../styles/variables.scss";

.container {
  background: linear-gradient(180deg, #1a1933 0%, #430f8b 100%);
	width: 100%;
	min-height: 100vh;	
}

.mintContainer {
  margin-top: 24px;
	padding-bottom: 100px;
}
 
.banner {
	opacity: 1 !important;
  width: 100%;
  height: 367px;
	background-position: center center;
	background-repeat: no-repeat;
  background-color:#2B0657;
	background-size: auto 100%;
}

@media(min-width: 640px){
	.banner{
		background-size: 100%;
	}
}

.centralImg {
	width: 100%;
	max-width: 860px;
	background-position: center center;
	background-repeat: no-repeat;
	height: 294px;
	margin: 24px 0;
}

.mintSection {
	box-sizing: border-box;
	border: 3px solid #00FFA1;
	border-radius: 14px;
	margin: 24px;
}

.mintButton {
	margin-top: 30px !important;
	width: 520px;
	height: 56px;
	background-color: #06C18D !important;
	border-radius: 14px;
	border: none;
	font-family: 'Montserrat-ExtraBold' !important;
	font-size: 24px !important;
	color: #FFFFFF !important;
  max-width: 90%;
}

.claimButton {
	margin-top: 30px !important;
	width: 520px;
	height: 56px;
	background-color: #F1983F !important;
	border-radius: 14px;
	border: none;
	font-family: 'Montserrat-ExtraBold' !important;
	font-size: 24px !important;
	color: #FFFFFF !important;
  max-width: 90%;
}


.mintButtonSmall {
	width: 288px;
	height: 52px;
	border-radius: 14px !important;
	background-color: transparent !important;
	font-family: 'Montserrat-ExtraBold' !important;
	font-size: 24px !important;
	color: #FFFFFF !important;
	margin-bottom: 10px !important;
	border: 4px solid #06C18D !important;
}

.bigSubtitle {
	font-family: 'Montserrat-ExtraBold' !important;
	font-size: 24px !important;
	line-height: 29px !important;
	color: #FFFFFF !important;
}

.mint {
  margin: 22px;
	width: 288px;
}

.pricing {
	width: 288px;
	height: 52px;
	background: rgba(0, 0, 0, 0.52) !important;
	border-radius: 14px;
	font-family: 'Montserrat-ExtraBold' !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 150% !important;
	color: #FFFFFF !important;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.stage {
  width: 304px;
  border-radius: 14px;
  border: 4px solid #8229ea;
  border-radius: 14px;
  margin: 15px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	filter: brightness(0.7) grayscale(1);
}

.stageTitle {
	font-size: 32px;
	color: #00FFA1;
	font-family: 'Montserrat-ExtraBold';
	margin: 4px 0;
}

.hour {
	font-size: 20px;
	color: #00FFA1;
	font-family: 'Montserrat-ExtraBold';
	margin: 4px 0;
}

.stageSubtitle {
	font-size: 24px;
	color: #FFFFFF;
	font-family: 'Montserrat-ExtraBold';
	margin: 4px 0;
}

.stageText {
	font-size: 18px;
	color: #FFFFFF;
	font-family: 'Montserrat-ExtraBold';
	margin: 4px 0;
	font-style: italic;
}

.cover {
  opacity: 1;
	transition: opacity 0.5s linear;
	height: calc(100vh - 61px);
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center top;
}

@media(max-width: 500px){
	.cover{
		background-size: auto 100%;

	}
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  padding: 24px;

	width: 640px;
	height: auto;
	min-height: 300px;
	background: linear-gradient(180deg, #61098B 0%, #2B0A6C 100%);
	border-radius: 33px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media(max-width: 640px){
	.modal{
		width: 300px;
		top: 50vh;
		left: 50vw;
	}
}

.modalTitle {
	font-size: 24px;
	color: #00FFA1;
	font-family: 'Montserrat-ExtraBold';
}

.modalText {
	padding: 24px;
	font-size: 18px;
	color: #FFFFFF;
	font-family: 'Montserrat-ExtraBold';
}

.modalButton {
	margin-top:64px !important;
	padding: 4px !important;
	width: 60%;
	height: 46px;
	background-color: #06C18D !important;
	border-radius: 14px;
	border: none;
	font-family: 'Montserrat-ExtraBold' !important;
	font-size: 18px !important;
	color: #FFFFFF !important;
}

.vipImg {
	width: 200px;
}
