@font-face {
  font-family: 'FatFrank-Free';
  src:  url('../fonts/families/FatFrank-Free.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro Black Az';
  src:  url('../fonts/families/Sofia Pro Black Az.otf') format('opentype');
}

@font-face {
  font-family: 'Samuelstype - MagicaRuby-VBold';
  src:  url('../fonts/families/Samuelstype - MagicaRuby-VBold.otf') format('opentype');
}

@font-face {
  font-family: 'Samuelstype - MagicaRuby-VLight';
  src:  url('../fonts/families/Samuelstype - MagicaRuby-VBold.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro Medium Az';
  src:  url('../fonts/families/Sofia Pro Medium Az.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src:  url('../fonts/families/Montserrat-ExtraBold.otf') format('opentype');
}

