 @import '../../../../styles/variables.scss';

.container{
	margin-top: $margin-top-section;
	padding-left: 80px;
	padding-right: 80px;
}
.title{
	color: $color-magic-green;
	font-size: 20px;
	text-transform: uppercase;
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;
	margin-bottom: 10px;
}
.mapa{
	width: 100%;
}


@media(max-width: 768px){

	.container{		
		padding-left: 10px;
		padding-right: 10px;
	}

}