@import '../../../../styles/variables.scss';

.contentStudios{
	background-image: url("../../../../assets/studios.webp"); 
  	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 121vh;	
}



  
