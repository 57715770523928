@import '../../styles/variables.scss';

.audio {
    position: fixed;
    left: 24px;
    bottom: 24px;
    z-index: 10;
}

@media(max-width: 640px){
	.audio{
		visibility: hidden;
	}
}