@import '../../../../styles/variables.scss';

.container {
    position: absolute;
    top: 300px;
    left: 50%;
}

.timer {
    position: relative; 
    left: -50%;
    padding: 24px 24px 24px 48px;
    width: auto;
    max-width: 80vw;
    margin-left: -8px;

    background: rgba(0, 0, 0, 0.66);
    border-radius: 45px;

    font-size: 24px;
	color: #FFFFFF;
	font-family: 'Montserrat-ExtraBold';
    letter-spacing: 1rem;
}

@media (min-width: 640px) {
	.timer {
        letter-spacing: 2rem;
        text-align: center;
        width: 500px;
	}
}

.legend {
    margin-top: 12px;
    display: flex;
    justify-content: space-around;
    font-size: 12px;
	color: #FFFFFF;
	font-family: 'Montserrat-ExtraBold';
    letter-spacing: normal;
    margin-left: -8px;
}

@media (min-width: 640px) {
	.legend {
        margin-left: -32px;
    }
}
