@import '../../styles/variables.scss';

.container{   
    padding-bottom: 8px; 
    padding-top: 2px;   
    padding-left: 40px;
    padding-right: 20px; 
    margin-top: 0 !important;
}
.menu {
    margin:auto;
    padding-left:20px;
    padding-top:5px;   
    height:25px;
    padding-bottom:5px; 
    float: right;    
}
.menu ul{
    list-style:none; /* Eliminamos los bullets */
    margin:0px; /* Quitamos los margenes */
    padding:0px; /* Quitamos el padding */
}
.menu ul li {
    float:left; /* Hacemos que el menu se muestre horizontal */
    padding-left:10px;
    padding-right:10px;
    text-transform: uppercase;    
}
.menu ul li a{
    text-decoration:none;
    color:#FFFFFF;    
    font-family: 'Sofia Pro Black Az';
    letter-spacing: -1px;
    font-size: 14px;
}
.logo{
    width: 120px;
}
.social{
    width: 26px;
}
.twitter{
    width: 26px;
}


@media(max-width: 768px){

    .iconMobile{
        margin-top: -10px;
        text-align: right;
        margin-right: -20px;        
    }
    .iconMobile button{
        color: #ffffff;
    }
    .iconMobile i{
        font-size: 24px;
    }
    .link{
        text-decoration: none;
        color: #ffffff;
    }
    .list .listItem:hover{
        background-color: #0d065b;
    }
    .mobileMenu{
        background-color: $color-magic-blue;          
    }
    
}