@import '../../styles/variables.scss';

.bannerMagic{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-magic-green;    
    padding-top: 10px;
    padding-bottom: 10px;
}
.title{
    text-align: center;
    font-family: 'Samuelstype - MagicaRuby-VBold';
    color: $color-magic-blue;
    font-size: 32px;    
}
.title span{
    text-transform: capitalize;
}
.title sup{
    font-size: 20px;
}
.btnDiscord{
    text-align: center; 
    margin-left: 80px;
    margin-right: 30px;
}
.btnDiscord button{
    background-color: #8229ea;
    color: #FFFFFF;
    font-family: 'FatFrank-Free';   
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 29px;
    height: 50px;

    &:hover{
        background-color: #933bff;
    }

}
.discord{
    width: 25px;
    margin-right: 8px;
}
.btnTwitter{
    text-align: center; 
}
.btnTwitter button{
    background-color: #8229ea;
    color: #FFFFFF;
    font-family: 'FatFrank-Free';   
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 29px;
    height: 50px;

    width: 4rem;
    height: 4rem;
    border-radius: 50%;    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px auto;
    padding: 3%;
    transform: scale(0.8);
    padding-left: 12px;

    &:hover{
        background-color: #933bff;
    }

}
.twitter{
    width: 30px;
    margin-right: 8px;
}


@media(max-width: 768px){

    .bannerMagic{
        display: block;        
    }
    .btnDiscord{
        text-align: center; 
        margin-left: 0px;
        margin-right: 0px;
    }
    .twitter{
        width: 30px;
        margin-right: 0px;        
    }
    .btnTwitter{
        text-align: center; 
        margin-top: 18px;
    }

}