@import '../../../../styles/variables.scss';

.container{
	padding-left: 200px;
	padding-right: 80px;
}
.title{
	color: $color-magic-green;
	font-size: 32px;
	font-family: 'FatFrank-Free';
	text-transform: uppercase;
	font-weight: normal;
}
.text{
	font-size: 18px;
	font-family: 'Sofia Pro Medium Az';
	color: #ffffff;
}

@media(max-width: 768px){

	.container{
		padding-left: 16px;
		padding-right: 16px;
	}

}
