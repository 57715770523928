@import '../../../../styles/variables.scss';

.container{
	background: linear-gradient(180deg, #1A1933 0%, #430F8B 100%);
}

.sectionGrid {
	width: 100%;
	padding-left: 0 !important;
}

.placeholder {
	padding-top: 24px;
	z-index: 1;
}

.banner {
	width: 100%;
	height: 200px;
	background-position: center center;
	background-repeat: no-repeat;
}

.gifs {
	width: 100%;
	max-width: 300px;
	height: auto;
	border: #00FFA1;
    border-radius: 24px;
    border-width: 4px;
    border-style: solid;
}

.artHeader {
	margin-top: 100px !important;
	margin-bottom: 48px !important;
	padding-left: 0 !important;
}

.video {
	width: 100%;
	max-width: 300px;
	border: $color-magic-green;
    border-radius: 24px;
    border-width: 4px;
    border-style: solid;
}

.subtitle {
	font-family: 'FatFrank-Free';
	font-size: 24px;
	text-align: center;
	color: $color-magic-green;
	letter-spacing: 2px;
	max-width: 12ch;
	margin-top: 24px !important;
}
