 @import '../../../../styles/variables.scss';

.container{
}

.sectionContainer{
	margin-top: 0px;
	margin-bottom: $margin-top-section;
}

@media (min-width: 900px) {
	.sectionContainer {
		margin-top: -40px !important;
	}
}

.title{
	color: $color-magic-green;
	font-size: 32px;
	text-transform: uppercase;
	font-family: 'FatFrank-Free';
	font-weight: 800;
	letter-spacing: 2px;
	margin-bottom: 10px;
	text-align: center;
	max-width: 35ch;
	padding: 0 24px 0 24px;
}

.sectionGrid {
	margin-top: 0 !important;
}

.sectionWeb {
	margin-top: 48px !important;
	margin-bottom: 96px !important;
	padding-left: 0 !important;
}

.placeholderArt {
	width: 175px;
	height: 132px;
	background: #49478A;
	border-radius: 36px;
}

.artIcon {
	width: 175px;
	height: auto;
}

.placeholderCircle {
	width: 106px;
	height: auto;
}

.placeholderCircleBig {
	width: 288px;
	height: auto;
}

.placeholderSquare {
	width: 288px;
	height: auto;
	margin-bottom: 20px;
}

.text {
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;
	margin-left: 24px !important;
	margin-right: 24px !important;
	font-size: 18px;
	line-height: 150%;
	color: #FFFFFF;
	max-width: 28ch !important;
}

.textLong {
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;
	font-size: 18px;
	line-height: 150%;
	color: #FFFFFF;
	max-width: 48ch !important;
	margin-top: 48px;
	margin-bottom: 48px;
	padding-left: 24px;
	padding-right: 24px;
}

.subtitle {
	font-family: 'FatFrank-Free';
	font-size: 24px;
	text-align: center;
	color: $color-magic-green;
	letter-spacing: 2px;
	max-width: 12ch;
}

.lineDivider {
	width: 4px;
	height: 150px;
	background: #8229EA;
}

.lineDot {
	width: 12px;
	height: 12px;
	background: #8229EA;
	border-radius: 50%;
}

.lineCircle {
	width: 20px;
	height: 20px;
	border: 3px solid #8229EA;
	border-radius: 50%;
}

.level {
	width: 106px;
	height: 106px;
	background-size: cover;
	background-image: url('/assets/icons/LV-Verde.png');
}
.level:hover {
	background-image: url('/assets/icons/LV-Dorado.png');
}

.star {
	width: 106px;
	height: 106px;
	background-size: cover;
	background-image: url('/assets/icons/Estrella-Verde.png');
}
.star:hover {
	background-image: url('/assets/icons/Estrella-Dorada.png');
}

.medal {
	width: 106px;
	height: 106px;
	background-size: cover;
	background-image: url('/assets/icons/Medalla-Borde-Verde.png');
}
.medal:hover {
	background-image: url('/assets/icons/Medalla-Borde-Dorado.png');
}

.share {
	width: 106px;
	height: 106px;
	background-size: cover;
	background-image: url('/assets/icons/Share-Verde.png');
}
.share:hover {
	background-image: url('/assets/icons/Share-Dorado.png');
}

.video {
	width: 100%;
    border-radius: 24px;
	margin-top: 64px !important;
	margin-bottom: 100px !important;
}
