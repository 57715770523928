@import '../../../../styles/variables.scss';

.container{
	background: #481090;
	background: linear-gradient(to top, #481090 0%, #080038 100%);	
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 1px;	
} 
.image{
	width: 500px;
	float: right;
}
.title{
	padding-top: 60px;
	text-transform: uppercase;
	color: $color-magic-green;
	font-size: 36px;
	font-family: 'FatFrank-Free';
	letter-spacing: 1px;	
}
.selector{
	padding-top: 92px;
	margin-right: 34px;
}
.option{
	height: 60px;	
	cursor: pointer;
	margin-bottom: 10px;
}

.active{
	border-right: 4px solid $color-magic-green;
	-moz-transition: all 300ms linear;
}
.inactive{
	border-right: 4px solid $color-purple;	
}
.text{
	font-size: 18px;
	font-family: 'Sofia Pro Medium Az';
	letter-spacing: 0.2px;
	color: #ffffff;
	margin-top: 30px;
	line-height: 26px;
}

.green{
	color: $color-magic-green;
}

.photo{	
	width: 110px;
	border-radius: 26px;
}
.name{
	color: $color-purple;
	font-size: 14px;
	margin-top: 4px;	
	font-family: 'Sofia Pro Medium Az';
}
.cargo{
	color: #ffffff;
	font-family: 'Sofia Pro Medium Az';
	font-size: 14px;
	margin-top: -12px;
}
.linkedin{
	margin-left: 40px;
	width: 30px;
}

.purple{
	color: $color-light-purple;
}

.hideScroll{
	overflow: hidden; 
}
.sector{	
	padding-right: 80px;	
	overflow: scroll;
	height: 812px;
	overflow: auto;
	margin-right: -100px;
	margin-bottom: -100px;	
	margin-top: 0px;	
}
.sector .text{	
	margin-top: 40px;
}
.team{
	padding-right: 40px;
}
.backImage{
	background-image: url("../../../../assets/pintor.webp");
	background-repeat: no-repeat;
	background-position: right;
	background-size: 500px 728px;
}
.filling{
	height: 30px;
}


@media(max-width: 768px){

	.container{
		padding-left: 16px;
		padding-right: 16px;
	}
	.title{		
		padding-top: 69px;
		font-size: 26px;		
	}
	.image{
        width: 100%;
    }

}